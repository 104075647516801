import React from 'react'
import { useTranslation } from 'react-i18next'
import { Container, Row, Col, Form, Button } from 'react-bootstrap'
import Fade from 'react-reveal/Fade'
import { StaticImage } from 'gatsby-plugin-image'

import PageIntro from '../components/pageIntro/pageIntro'
import Layout from '../components/layout'
import SEO from '../components/seo'

function SecondPage() {
    const { t } = useTranslation('contact')

    return (
        <Layout darkHeader>
            <SEO title="contact:title" />
            <PageIntro title={t('title')} subtitle={t('subtitle')} />

            <section id="contactForm" className="content" style={{ paddingTop: '0px' }}>
                <Container>
                    <div className="mt-4 border-0 mb-4">
                        <Row>
                            <Col lg={4} md={4}>
                                <Fade right duration={1000} delay={400} distance="10px">
                                    <div className="card-body d-flex align-items-center justify-content-around c-detail">
                                        <div className="mr-3 align-self-center">
                                            <StaticImage
                                                src="../assets/images/address.png"
                                                alt="image"
                                                width={60}
                                                height={60}
                                            />
                                        </div>
                                        <div className="">
                                            <h6 className="font-weight-medium">{t('address')}</h6>
                                            <p className="">
                                                Ανάφη 840 09
                                                <br /> Κυκλάδες
                                            </p>
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col lg={4} md={4}>
                                <Fade right duration={1000} delay={400} distance="10px">
                                    <div className="card-body d-flex align-items-center justify-content-around c-detail">
                                        <div className="mr-3 align-self-center">
                                            <StaticImage
                                                src="../assets/images/phone-call.png"
                                                alt="address"
                                                width={60}
                                                height={60}
                                            />
                                        </div>
                                        <div className="">
                                            <h6 className="font-weight-medium">{t('phone')}</h6>
                                            <p className="">
                                                2286 061234
                                                <br /> +30 697 714 4310
                                            </p>
                                        </div>
                                    </div>
                                </Fade>
                            </Col>
                            <Col lg={4} md={4}>
                                <Fade right duration={1000} delay={400} distance="10px">
                                    <div className="card-body d-flex align-items-center justify-content-around c-detail">
                                        <div className="mr-3 align-self-center">
                                            <StaticImage
                                                src="../assets/images/email.png"
                                                alt="email_icon"
                                                width={60}
                                                height={60}
                                            />
                                        </div>
                                        <div className="">
                                            <h6 className="font-weight-medium">{t('email')}</h6>
                                            <p className="">anafi71@gmail.com</p>
                                        </div>
                                    </div>
                                </Fade >
                            </Col>
                        </Row>
                    </div>

                    <Row className="mb-5">
                        <Col lg={6} className="mb-4">
                            <div className="card-shadow">
                                <StaticImage
                                    // height={450}
                                    // style={{borderRadius: '4px'}}
                                    alt="profile picture"
                                    src="../assets/images/contact.jpg"
                                />
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="contact-box">
                                <Form
                                    name="contact"
                                    method="POST"
                                    netlify-honeypot="bot-field"
                                    data-netlify="true"
                                >
                                    <input type="hidden" name="bot-field" />
                                    <input type="hidden" name="form-name" value="contact" />
                                    <Row>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Control
                                                    required
                                                    size="lg"
                                                    type="text"
                                                    placeholder="name"
                                                    name="name"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Control
                                                    required
                                                    size="lg"
                                                    type="text"
                                                    placeholder={t('email')}
                                                    name="email"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Control
                                                    required
                                                    size="lg"
                                                    type="text"
                                                    placeholder={t('phone')}
                                                    name="phone"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Form.Group>
                                                <Form.Control
                                                    required
                                                    size="lg"
                                                    as="textarea"
                                                    placeholder={t("message")}
                                                    rows="3"
                                                    name="message"
                                                />
                                            </Form.Group>
                                        </Col>
                                        <Col md={12}>
                                            <Button type="submit" className="cta-btn cta-btn--hero">
                                                {t('submit')}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </section>
        </Layout>
    )
}

export default SecondPage
