import React from 'react'
import PropTypes from 'prop-types'
import Fade from 'react-reveal/Fade'
import { Container } from 'react-bootstrap'
import Title from '../title/title'

const PageIntroHeader = ({ title, subtitle, description }) => (
    <div style={{ marginTop: '8rem' }}>
        <Container>
            <Fade bottom duration={1000} delay={200} distance="30px">
                <Title title={title} />
            </Fade>
            <Fade bottom duration={1000} delay={400} distance="30px">
                <h4 style={{ fontWeight: '200' }} className="page-intro-subtitle mt-4">
                    {subtitle}
                </h4>
            </Fade>
            <Fade bottom duration={1000} delay={400} distance="30px">
                <p className="mt-4">{description}</p>
            </Fade>
        </Container>
    </div>
)

export default PageIntroHeader

PageIntroHeader.propTypes = {
    title: PropTypes.string,
    subtitle: PropTypes.string,
    description: PropTypes.string,
}

PageIntroHeader.defaultProps = {
    title: '',
    subtitle: '',
    description: '',
}
